import React from 'react';
import { Container, Grid } from '@mui/material';
import Todo from './Todo';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container maxWidth={false}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Todo />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default Home;
