import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { opportunityDetailActions } from './opportunityDetailState';
import { getDataById, saveData, getDatas } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY_ID, payload);
    yield put(opportunityDetailActions.getDataSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const result = yield call(saveData, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY, payload);
    yield put(opportunityDetailActions.saveDataSuccess(result));
  } catch (_) { }
}

function* getOrderDetails({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY_ORDER, payload);
    yield put(opportunityDetailActions.getOrderDetailsSuccess(result));
  } catch (_) { }
}

function* opportunityDetailSaga() {
  yield takeLatest('opportunityDetail/getData', getData);
  yield takeLatest('opportunityDetail/saveData', save);
  yield takeLatest('opportunityDetail/getOrderDetails', getOrderDetails);
}

export default opportunityDetailSaga;
