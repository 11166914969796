import React, { useEffect, useRef, useState } from 'react';
import {
  CircularProgress, Box, Snackbar, IconButton, Alert, Slide, Tooltip, Chip as MuiChip,
  InputAdornment, OutlinedInput, TablePagination, Link as MuiLink, useMediaQuery, FormHelperText,
  Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { LoadingButton as MuiButton } from '@mui/lab';
import {
  Close as CloseIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon,
  Help as HelpIcon,
} from '@mui/icons-material';
import { useParams, Link as RouteLink } from 'react-router-dom';
import { isValid, format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker as MuiTimePicker, LocalizationProvider } from '@mui/x-date-pickers';

export function FileViewer(props) {
  const { url, onError } = props;
  const iframeRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const style = {
    width: '100%',
    height: '700px',
    border: 'none',
  };

  useEffect(() => {
    const iframe = iframeRef.current;
    setLoading(true);
    const handleLoad = () => {
      try {
        setLoading(false);
      } catch (error) {}
    };

    if (iframe) {
      iframe.addEventListener('load', handleLoad);
    }

    // クリーンアップ関数
    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleLoad);
      }
    };
  }, [url]);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = url.downloadUrl;
    link.download = url.fileName;
    link.click();
  };

  return (
    <div>
      {loading && (
        <Box textAlign="center">
          <Box mb={1}>Viewを読み込んでいます...</Box>
          <CircularProgress />
        </Box>
      )}
      <Box mb={1} textAlign="center">
        <Button variant="contained" color="primary" onClick={handleDownload}>
          ダウンロード
        </Button>
      </Box>
      <Box textAlign="center"><iframe ref={iframeRef} src={url.previewUrl} onError={onError} style={style} title="viewer" /></Box>
    </div>
  );
}

export function withParams(Component) {
  function WithParams(props) {
    return <Component {...props} params={useParams()} />;
  }
  return WithParams;
}

export function TimePicker(props) {
  const {
    value,
    onChange,
    name,
    isDisabled,
    fullWidth,
    error,
  } = props;

  const onLocalChange = (changeValue) => {
    let formatValue = !changeValue ? '' : changeValue;
    if (isValid(formatValue)) {
      formatValue = format(formatValue, 'HH:mm');
    }
    const event = {
      target: {
        name,
        value: formatValue,
      },
    };

    onChange(event);
  };

  const fnsValue = value ? new Date(`1999/12/31 ${value}`) : null;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <MuiTimePicker
        value={fnsValue}
        disabled={isDisabled}
        sx={fullWidth ? { width: '100%' } : {}}
        onChange={onLocalChange}
        slotProps={{ textField: { variant: 'outlined', error } }}
      />
    </LocalizationProvider>
  );
}

export function TabPanel(props) {
  const { children, value, index } = props;

  if (value !== index) {
    return null;
  }
  return children;
}

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export function SuccessSnackbar(props) {
  const { open, onClose, message } = props;

  const handleCloseCheck = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleCloseCheck}
      TransitionComponent={TransitionUp}
    >
      <Alert
        elevation={10}
        variant="filled"
        onClose={handleCloseCheck}
        severity="success"
        action={(
          <IconButton aria-label="delete" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
          )}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export function PasswordInput(props) {
  const {
    name, value, onChange, error,
  } = props;
  const [showPassword, setShowPassword] = useState(true);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <OutlinedInput
      fullWidth
      type={showPassword ? 'password' : 'text'}
      value={value}
      name={name}
      error={error}
      inputProps={{ maxLength: 16 }}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </InputAdornment>
      )}
      autoComplete="current-password"
      onChange={onChange}
    />
  );
}

export function Pagination({
  totalElements = 0,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
}) {
  return (
    <TablePagination
      rowsPerPageOptions={[50, 100, 150, 200]}
      component="div"
      count={totalElements}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      labelRowsPerPage="1ページの表示件数："
      labelDisplayedRows={({ from, to, count }) => `全${count}件中 ${from}件から ${to}件までを表示中`}
    />
  );
}

export function Link({ children, to, target }) {
  return (
    <MuiLink
      component={RouteLink}
      to={to}
      target={target}
      sx={{
        color: '#1E90FF',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      {children}
    </MuiLink>
  );
}

export function FormTitle({
  title, isRequired = false, attention, isNonFlex,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Box
      sx={{ fontWeight: 'bold', flexGrow: (isNonFlex || isMobile) ? null : 1 }}
      display="flex"
    >
      <Box justifyContent="center" display="flex" mb={isNonFlex ? 1 : 0}>
        {title}
        {attention && (
          <Tooltip title={`${attention}が検索できます。複数のワードを検索する場合は、スペース区切りで入力してください。`}>
            <HelpIcon />
          </Tooltip>
        )}
      </Box>
      {isRequired && (
        <MuiChip
          label="必須"
          size="small"
          sx={{
            color: '#fff',
            backgroundColor: '#f50057',
            borderRadius: 0,
            marginLeft: (isNonFlex || isMobile) ? '5px' : 'auto',
          }}
        />
      )}
    </Box>
  );
}

export function FormErrorText({ children = null }) {
  return (
    <FormHelperText error style={{ fontSize: '0.9rem' }}>{children}</FormHelperText>
  );
}

export function LoadingButton(props) {
  const { onClick, children } = props;
  const loading = useSelector((state) => state.commonStore.isLoading);

  return (
    <MuiButton onClick={onClick} loading={loading} {...props}>
      {children}
    </MuiButton>
  );
}
