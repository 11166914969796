import { createSlice } from '@reduxjs/toolkit';

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    todos: [],
    notices: [],
  },
  reducers: {
    getTodo: () => {},
    getTodoSuccess: (state, action) => {
      state.todos = action.payload;
    },
  },
});

export const homeActions = homeSlice.actions;

export default homeSlice.reducer;
