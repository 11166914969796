import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Paper, TextField, Box, Button, Chip,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Validation from './validation';
import { opportunityDetailActions } from '../../../../redux/opportunity/opportunityDetail/opportunityDetailState';
import { TimePicker, FormErrorText } from '../../../../components/atoms/Base';
import DataGrid from '../../../../components/templates/DataGrid';

export default function OpportunityDetailInfo(props) {
  const { id } = props;

  const DEFAULT_FORM = {
    id: '', // 注文ID
    shopName: '', // 店舗名
    opportunityNumber: '', // 案件番号
    heldDate: '', // 開催日
    heldCharTime: '', // 開催時間
    deliveryAddress: '', // 納品先住所
    deliveryRouteFilePath: '', // 搬入経路ファイルパス
    deliveryRouteText: '', // 搬入経路テキスト
    deliveryRouteFileName: '', // 搬入経路ファイル名
    paymentMethodName: '', // 支払方法名
    customerBillPrice: '', // 顧客請求額
    companyName: '', // 所属先会社
    branchName: '', // 支店営業所
    customerName: '', // お客様名
    customerPhone: '', // 電話番号
    driverName: '', // ドライバー名
    driverPhone: '', // ドライバー電話番号
    driverPickupTime: '', // ドライバーピックアップ時間
    opportunityShopRemarks: '', // 店舗向け備考
  };
  const DEFAULT_ERROR_MESSAGES = {
    driverPickupTime: '', // ドライバーピックアップ時間
  };

  const dispatch = useDispatch();
  const [form, setForm] = useState(DEFAULT_FORM);
  const [inputForm, setInputForm] = useState(DEFAULT_FORM);
  const [isEdit, setIsEdit] = useState(!id);
  const [errorMessages, setErrorMessages] = useState(DEFAULT_ERROR_MESSAGES);
  const [loading, setLoading] = React.useState(false);

  const data = useSelector((state) => state.opportunityDetailStore.data);

  React.useEffect(() => {
    if (id && data) {
      setForm(data);
      setInputForm(data);
      setLoading(false);
    }
  }, [data]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setInputForm({ ...inputForm, [name]: value });
  };

  const onEdit = () => {
    setIsEdit(true);
    setLoading(true);
    dispatch(opportunityDetailActions.getData({ id: form.id }));
  };

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(inputForm).forEach((key) => {
      const msg = Validation.formValidate(key, inputForm[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    if (isError) {
      setErrorMessages(tempMessage);
    }
    return isError;
  };

  const onSave = () => {
    setLoading(true);
    if (beforeSaveCheck()) {
      setLoading(false);
      return;
    }
    dispatch(opportunityDetailActions.saveData(inputForm));
    setIsEdit(false);
  };

  return (
    <>
      <Box
        mr={1}
        style={{
          minHeight: '48px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end',
        }}
      >
        {!isEdit && (
          <Button variant="contained" size="small" onClick={onEdit}>編集</Button>
        )}
      </Box>
      <Paper elevation={3} style={{ padding: '10px', marginBottom: 8 }}>
        <Box mb={3}><Chip sx={{ fontWeight: 'bold' }} label="ピックアップ時間" /></Box>
        <DataGrid title="ドライバー名" value={form.driverName} isEdit={isEdit}>
          <TextField inputProps={{ maxLength: 50 }} value={inputForm.driverName} fullWidth name="driverName" onChange={onChange} />
        </DataGrid>
        <DataGrid title="ドライバー電話番号" value={form.driverPhone} isEdit={isEdit}>
          <TextField inputProps={{ maxLength: 15 }} value={inputForm.driverPhone} fullWidth name="driverPhone" onChange={onChange} />
        </DataGrid>
        <DataGrid title="ドライバーピックアップ時間" value={form.driverPickupTime} isEdit={isEdit}>
          <TimePicker value={inputForm.driverPickupTime} name="driverPickupTime" onChange={onChange} fullWidth />
          <FormErrorText>{errorMessages.driverPickupTime}</FormErrorText>
        </DataGrid>

        {isEdit && (
        <Box textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
          {id && (
          <Button color="inherit" variant="contained" size="small" onClick={() => setIsEdit(false)} style={{ marginRight: '10px' }}>キャンセル</Button>
          )}
          <LoadingButton variant="contained" size="small" onClick={onSave} loading={loading}>保存</LoadingButton>
        </Box>
        )}
      </Paper>
      <Paper elevation={3} style={{ padding: '10px', marginBottom: 8 }}>
        <Box mb={3}><Chip sx={{ fontWeight: 'bold' }} label="基本情報" /></Box>
        <DataGrid title="店舗名" value={form.shopName} />
        <DataGrid title="案件番号" value={form.opportunityNumber} />
        <DataGrid title="開催日" value={form.heldDate} />
        <DataGrid title="開催時間" value={form.heldCharTime} />
        <DataGrid title="納品先住所" value={form.deliveryAddress} />
        <DataGrid title="搬入経路" value={form.deliveryRouteText} />
      </Paper>
      <Paper elevation={3} style={{ padding: '10px', marginBottom: 8 }}>
        <Box mb={3}><Chip sx={{ fontWeight: 'bold' }} label="支払情報" /></Box>
        <DataGrid title="支払方法" value={form.paymentMethodName} />
        <DataGrid title="顧客請求額" value={form.customerBillPrice} />
      </Paper>
      <Paper elevation={3} style={{ padding: '10px', marginBottom: 8 }}>
        <Box mb={3}><Chip sx={{ fontWeight: 'bold' }} label="お客様情報" /></Box>
        <DataGrid title="所属先会社" value={form.companyName} />
        <DataGrid title="支店営業所" value={form.branchName} />
        <DataGrid title="お客様名" value={form.customerName} />
        <DataGrid title="電話番号" value={form.customerPhone} />
      </Paper>
      <Paper elevation={3} style={{ padding: '10px', marginBottom: 8 }}>
        <Box mb={3}><Chip sx={{ fontWeight: 'bold' }} label="備考情報" /></Box>
        <DataGrid title="店舗向け備考" value={form.opportunityShopRemarks} />
      </Paper>
    </>
  );
}
