import React from 'react';
import {
  TableCell, TableContainer, Table, TableBody, TableRow, Button, Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function SmartPhoneTable(props) {
  const {
    row, headCells, url, onOpen,
    onDelete, onEdit, openedMenuRowId,
    setOpenedMenuRowId, setOpenDelete,
  } = props;
  const navigate = useNavigate();

  const onDetail = () => {
    navigate(url + row.id);
  };

  return (
    <TableCell sx={{ padding: 1 }}>
      <Box textAlign="right" sx={{ minHeight: '45px' }}>
        {(onDelete && row.deleteable) && (
          <Button
            sx={{ margin: 1 }}
            variant="outlined"
            size="small"
            color="error"
            onClick={() => { setOpenDelete(true); }}
          >
            削除
          </Button>
        )}
        {onEdit && (
          <Button
            sx={{ margin: 1 }}
            variant="outlined"
            size="small"
            onClick={(e) => {
              onEdit(e, openedMenuRowId, row);
              setOpenedMenuRowId(null);
            }}
          >
            編集
          </Button>
        )}
        {url && (
          <Button
            sx={{ margin: 1 }}
            variant="outlined"
            size="small"
            onClick={onDetail}
          >
            詳細
          </Button>
        )}
        {onOpen && (
          <Button
            sx={{ margin: 1 }}
            variant="outlined"
            size="small"
            onClick={onOpen}
          >
            開く
          </Button>
        )}
      </Box>
      <TableContainer>
        <Table>
          <TableBody>
            {headCells.map((headCell) => (
              <TableRow
                key={row.dummyId || row.id}
                sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
              >
                <TableCell sx={{ padding: '5px' }}>{`${headCell.label}`}</TableCell>
                <TableCell sx={{ padding: '5px' }}>
                  {headCell.numeric && typeof row[headCell.id] === 'number' && !Number.isNaN(row[headCell.id])
                    ? row[headCell.id].toLocaleString()
                    : row[headCell.id]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableCell>
  );
}
