import React from 'react';
import {
  Table, TableBody, TableCell, Box, Paper,
  TableHead, TableRow, TableContainer,
  Toolbar, Typography, useMediaQuery,
  Checkbox, Tooltip, IconButton, Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Close as CloseIcon } from '@mui/icons-material';
import DeleteButton from '../../../components/atoms/DeleteButton';
import { Pagination } from '../../../components/atoms/Base';
import TableHeaderCell from '../../../components/atoms/TableHeaderCell';
import PcTable from './PcTable';
import SmartPhoneTable from './SmartPhoneTable';

export default function DataTable(props) {
  const [selected, setSelected] = React.useState([]);
  const isPc = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const navigate = useNavigate();
  const {
    rows,
    procName,
    headCells,
    totalElements,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
    url,
  } = props;

  const onAdd = () => {
    navigate(url);
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  React.useEffect(() => {
    handleHeaderClose();
  }, [rows]);

  const handleSelectAllClick = (event) => {
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows
        .filter((row) => row.deleteable)
        .map((n) => n.id);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, name];
    } else {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1),
      ];
    }

    setSelected(newSelected);
  };

  const onDelete = () => {
    props.onDelete(selected);
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const colSpan = headCells.length + 1;

  return (
    <Box mb={2}>
      <Paper elevation={3}>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '' }}>
          {selected.length > 0 ? (
            <>
              <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
              <DeleteButton onDelete={onDelete} procName={procName} />
            </>
          ) : (
            <>
              <Typography style={{ flex: '1' }} color="inherit" variant="subtitle1" component="div" />
              <Tooltip title={`${procName}の追加`}>
                <Button color="primary" variant="outlined" onClick={onAdd}>{`${procName}の追加`}</Button>
              </Tooltip>
            </>
          )}
        </Toolbar>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell key="table-header-check" padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0
                      && rows.filter((row) => row.deleteable).length !== selected.length
                    }
                    checked={
                      selected.length > 0
                      && rows.filter((row) => row.deleteable).length === selected.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                    color="primary"
                    disabled={rows.filter((row) => row.deleteable).length === 0}
                  />
                </TableCell>
                {isPc && headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
                {!isPc && (
                  <TableCell> </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      {row.deleteable && (
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      )}
                    </TableCell>
                    {isPc && (
                      <PcTable
                        row={row}
                        headCells={headCells}
                        url={url}
                      />
                    )}
                    {!isPc && (
                      <SmartPhoneTable
                        row={row}
                        url={url}
                        headCells={headCells}
                      />
                    )}
                  </TableRow>
                );
              })}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={colSpan}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
