import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table, Box, Button, IconButton, Paper,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { opportunityListActions } from '../../../../redux/opportunity/opportunity/opportunityState';
import getCalnedarMonthly from './opportunityProc';
import CalendarDetailList from './CalendarDetail';

const HeaderTd = styled('th')(({ rested }) => ({
  border: 'solid 1px #DDD',
  width: '14.2%',
  verticalAlign: 'top',
  ...(rested && {
    color: 'red',
  }),
}));

const DataTd = styled('td')(({ disabled, rested }) => ({
  border: 'solid 1px #DDD',
  width: '14.2%',
  height: '80px',
  verticalAlign: 'top',
  ...(disabled && {
    background: '#e6e6e6',
    color: '#999999',
  }),
  ...(rested && {
    color: 'red',
  }),
  ...(!disabled && {
    '&:hover': {
      background: '#f1f3f4',
    },
  }),
}));

export default function Calendar(props) {
  const { onSearch, searchCondition } = props;
  const dispatch = useDispatch();
  const [detailOpen, setDetailOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const [detailCondition, setCondition] = useState({
    page: 0,
    records: 50,
    order: 'asc',
    orderBy: '',
    heldDate: '',
    cityId: '',
  });

  const calendarDatas = useSelector((state) => state.opportunityListStore.calendarDatas);

  useEffect(() => {
    if (searchCondition.targetMonth) {
      const calendarMonthly = getCalnedarMonthly(searchCondition.targetMonth);
      setCalendars(calendarMonthly);
    }
  }, [searchCondition.targetMonth]);

  useEffect(() => {
    if (calendarDatas) {
      setDatas(calendarDatas);
    }
  }, [calendarDatas]);

  const onDetailOpen = (_, heldDate, cityId) => {
    const tmpCondition = {
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
      heldDate,
      cityId,
    };
    setCondition(tmpCondition);
    dispatch(opportunityListActions.getDatas(tmpCondition));
    setDetailOpen(true);
  };

  const onClose = () => {
    setDetailOpen(false);
  };

  const onBeforeMonth = () => {
    const { targetMonth } = searchCondition;
    const curDate = new Date(targetMonth);
    curDate.setMonth(curDate.getMonth() - 1);
    const newDate = format(curDate, 'yyyy/MM/dd');
    onSearch(newDate);
  };

  const onNextMonth = () => {
    const { targetMonth } = searchCondition;
    const curDate = new Date(targetMonth);
    curDate.setMonth(curDate.getMonth() + 1);
    const newDate = format(curDate, 'yyyy/MM/dd');
    onSearch(newDate);
  };

  const onResearch = (tmpConditon) => {
    dispatch(opportunityListActions.getDatas(tmpConditon));
    setCondition(tmpConditon);
  };

  return (
    <>
      <Paper elevation={0} style={{ marginBottom: '10px' }}>
        <Box style={detailOpen ? { paddingLeft: '0px', marginLeft: '0px', width: '50%' } : null}>
          <Box mb={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton style={{ marginRight: '5px' }} onClick={onBeforeMonth} size="medium">
              <NavigateBeforeIcon />
            </IconButton>
            <Box
              style={{
                fontWeight: 'bold',
                display: 'inline-flex',
                alignItems: 'center',
              }}
            >
              {searchCondition.targetMonth.slice(0, -3)}
            </Box>
            <IconButton style={{ marginLeft: '5px' }} onClick={onNextMonth} size="medium">
              <NavigateNextIcon />
            </IconButton>
          </Box>
          <Table
            width="100%"
            sx={{
              border: 'solid 1px #DDD',
              borderCollapse: 'collapse',
              textAlign: 'center',
              tableLayout: 'fixed',
            }}
          >
            <thead>
              <tr>
                {['日', '月', '火', '水', '木', '金', '土'].map((week) => (
                  <HeaderTd key={week} rested={week === '日' || week === '土'}>{week}</HeaderTd>
                ))}
              </tr>
            </thead>
            <tbody>
              {calendars.map((weekRow, index) => (
                <tr key={`${weekRow[index].date}month`}>
                  {weekRow.map((item) => {
                    const dayDatas = datas.filter(
                      (data) => data.targetDate === item.formatDate,
                    );
                    return (
                      <DataTd
                        key={item.day}
                        disabled={!item.isSomeMonth}
                      >
                        <Box>
                          <div>{item.day}</div>
                          {dayDatas.map((data) => (
                            <Box key={data.targetDate + data.cityName} style={{ display: 'flex', justifyContent: 'center' }}>
                              <Box>
                                <Button style={{ padding: 0 }} variant="text" onClick={(e) => onDetailOpen(e, item.formatDate, data.cityId)}>{`${data.cityName || '市区町村未設定'}  ${data.orderCount}件`}</Button>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </DataTd>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      </Paper>
      <CalendarDetailList
        open={detailOpen}
        onClose={onClose}
        onSearch={onResearch}
        searchCondition={detailCondition}
      />
    </>
  );
}
