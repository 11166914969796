import { createSlice } from '@reduxjs/toolkit';

export const opportunityAttachSlice = createSlice({
  name: 'opportunityAttach',
  initialState: {},
  reducers: {
    getFiles: () => {},
    getFilesSuccess: (state, action) => {
      state.files = action.payload;
    },
    getDeliveryFiles: () => {},
    getDeliveryFilesSuccess: (state, action) => {
      state.deliveryFiles = action.payload;
    },
  },
});

export const opportunityAttachActions = opportunityAttachSlice.actions;

export default opportunityAttachSlice.reducer;
