import {
  isValid,
} from 'date-fns';

export default class CommonValidation {
  /**
   * 必須チェック
   * @param {*} value
   * @returns
   */
  static requiredValue = (value) => {
    if (!value) return '入力してください';
    return '';
  };

  /**
   * 選択リスト用必須チェック
   * @param {*} value
   * @returns
   */
  static requiredSelect = (value) => {
    if (!value) return '選択してください';
    return '';
  };

  /**
   * 0未満数値チェック
   * @param {*} value 数値
   * @returns
   */
  static requiredNumber = (value) => {
    if (!value) return '入力してください';
    if (Math.sign(value) < 1) return '1以上の値で入力してください';
    return '';
  };

  /**
   * 日付形式チェック
   * @param {*} value 日付
   * @returns
   */
  static nonRequiredDate = (value) => {
    if (!value) return '';
    if (!isValid(new Date(value))) return '日付形式で入力してください';
    return '';
  };

  static requiredDate = (value) => {
    if (!value) return '';
    if (!isValid(new Date(value))) return '日付形式で入力してください';
    return '';
  };

  /**
   * 時間形式チェック
   * @param {*} value 日付
   * @returns
   */
  static nonRequiredTime = (value) => {
    if (!value) return '';
    const fnsValue = new Date(`1999/12/31 ${value}`);
    if (!isValid(fnsValue)) return '時間形式で入力してください';
    return '';
  };

  /**
   * メールアドレス形式チェック
   * @param {*} value メールアドレス
   * @returns
   */
  static requiredEmail = (value) => {
    if (!value) return '入力してください';
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    if (!regex.test(value)) return '正しい形式でメールアドレスを入力してください';
    return '';
  };

  /**
   * メールアドレス形式チェック
   * @param {*} value メールアドレス
   * @returns
   */
  static nonRequiredEmail = (value) => {
    if (!value) return '';
    const targets = value.split(',');
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    let isError = false;
    targets.forEach((email) => {
      if (!regex.test(email)) {
        isError = true;
      }
    });
    if (isError) return '正しい形式でメールアドレスを入力してください';
    return '';
  };

  /**
   * パスワード形式チェック
   * @param {*} value パスワード
   * @returns
   */
  static requiredPassword = (value) => {
    if (!value) return '入力してください';
    const regex = /^[A-Za-z\d@$#!._]{8,16}$/i;
    if (!regex.test(value)) return '8文字以上16文字以下で半角英数字、記号( ! # $ @ . _)のみで入力してください';
    return '';
  };

  /**
   * パスワード一致チェック
   * @param {*} password パスワード
   * @param {*} passwordCheck 確認パスワード
   * @returns
   */
  static requiredPasswordCheck = (password, passwordCheck) => {
    if (!password || !passwordCheck) return '入力してください';
    const regex = /^[A-Za-z\d@$#!._]{8,16}$/i;
    if (!regex.test(password)) return '8文字以上16文字以下で半角英数字、記号( ! # $ @ . _)のみで入力してください';
    if (passwordCheck && password !== passwordCheck) return 'パスワードが一致しません';
    return '';
  };
}
