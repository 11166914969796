import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import { urls } from '../../constants/frontUrls';

const Breadcrumbs = styled(MuiBreadcrumbs)(() => ({
  background: '#fff',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  paddingLeft: 10,
}));

const breadcrumbNameMap = new Map();

breadcrumbNameMap.set(urls.COMMON.HOME.url, urls.COMMON.HOME.name);

urls.OPPORTUNITY.concat(urls.SYSTEM_SETTING)
  .forEach((c) => {
    const url = c.url.split('/').filter((x) => !x.match(/:/)).join('/');
    breadcrumbNameMap.set(url, c.name);
  });

function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}

function isString(value) {
  const number = parseInt(value, 10);
  if (value && Number.isNaN(number)) {
    return true;
  }
  return false;
}

export default function RouterBreadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => isString(x));
  return breadcrumbNameMap.get(`/${pathnames[0]}`) ? (
    <Breadcrumbs aria-label="breadcrumb">
      {pathnames.map((_, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color="text.primary" key={to}>
            {breadcrumbNameMap.get(to)}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="inherit" to={to} key={to}>
            {breadcrumbNameMap.get(to)}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  ) : null;
}
