import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Drawer, IconButton, Divider, useMediaQuery,
} from '@mui/material';
import {
  Clear as ClearIcon,
} from '@mui/icons-material';
import detailUrl from '../../../../constants/frontUrls';
import DataTable from '../../../../components/templates/dataTable/DataTable';

export default function CalendarDetailList(props) {
  const {
    searchCondition, onSearch, onClose, open,
  } = props;
  const dbDatas = useSelector((state) => state.opportunityListStore.datas);
  const [datas, setDatas] = useState({ totalElements: 0 });
  const isPc = useMediaQuery((theme) => theme.breakpoints.up('md'));

  useEffect(() => {
    if (dbDatas) {
      setDatas(dbDatas);
    }
  }, [dbDatas]);

  const headCells = [
    { id: 'orderNumber', label: '注文番号', sortId: 'order_number' },
    { id: 'opportunityNumber', label: '案件番号', sortId: 'opportunity_number' },
    { id: 'shopName', label: '店舗名', sortId: 'shop_name' },
    { id: 'heldDate', label: '開催日', sortId: 'held_date' },
    { id: 'deliveryAddress', label: '納品先', sortId: 'delivery_address' },
    { id: 'driverName', label: 'ドライバー名', sortId: 'driver_name' },
    { id: 'driverPhone', label: 'ドライバー電話番号', sortId: 'driver_phone' },
    { id: 'driverPickupTime', label: 'ドライバーピックアップ時間', sortId: 'driver_pickup_time' },
  ];

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    onSearch(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    onSearch(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      order,
      orderBy,
    };
    onSearch(tempCondition);
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      sx={{
        '& .MuiDrawer-paper': {
          width: isPc ? '50%' : '100%',
        },
        flexShrink: 0,
        whiteSpace: 'nowrap',
      }}
      open={open}
    >
      <div>
        <IconButton onClick={onClose}>
          <ClearIcon />
        </IconButton>
      </div>
      <Divider />
      <DataTable
        url={detailUrl.OPPORTUNITY_CALENDAR_DETAIL}
        rows={datas.content || []}
        headCells={headCells}
        procName="案件"
        page={searchCondition.page}
        rowsPerPage={searchCondition.records}
        totalElements={datas.totalElements || 0}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleRequestSort={handleRequestSort}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
      />
    </Drawer>
  );
}
