import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Avatar, ListItemAvatar, ListItemText, Divider,
  ListItem, List, ListItemButton,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import { homeActions } from '../../redux/home/homeState';
import detailUrl from '../../constants/frontUrls';

export default function Todo() {
  const dbDatas = useSelector((state) => state.homeStore.todos);

  const [datas, setDatas] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(homeActions.getTodo());
  }, []);
  useEffect(() => {
    if (dbDatas.length > 0) {
      setDatas(dbDatas);
    }
  }, [dbDatas]);

  const onClick = (_, data) => {
    navigate(detailUrl.OPPORTUNITY_CHECK_DETAIL + data.id);
  };

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {datas.map((data, index) => (
        <ListItemButton onClick={(e) => onClick(e, data)} key={data.id}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <CheckIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="ピックアップ情報の登録をしてください。"
            />
          </ListItem>
          {(datas.length - 1) !== index && (<Divider variant="inset" component="li" />)}
        </ListItemButton>
      ))}
    </List>
  );
}
