import React from 'react';
import {
  DialogContent, DialogActions,
  Box, Button,
} from '@mui/material';
import { urls } from '../../../constants/frontUrls';
import Dialog from '../../../components/atoms/Dialog';

export default function LogoutDialog(props) {
  const { isOpen } = props;
  const onClose = () => {
    localStorage.clear();
    window.location.href = urls.COMMON.LOGIN.url;
  };

  return (
    <Dialog
      fullWidth
      title="ログアウト"
      isOpen={isOpen}
      onClose={onClose}
    >
      <DialogContent dividers>
        <Box mt={1}>
          ログアウトします。
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          ログアウト
        </Button>
      </DialogActions>
    </Dialog>
  );
}
