import { createSlice } from '@reduxjs/toolkit';

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    datas: {},
  },
  reducers: {
    getDatas: () => {},
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
  },
});

export const orderActions = orderSlice.actions;

export default orderSlice.reducer;
