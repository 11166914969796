import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { urls } from './constants/frontUrls';
import Login from './pages/login/Login';
import ResetPasswordToken from './pages/login/ResetPasswordToken';
import ResetPassword from './pages/login/ResetPassword';
import Home from './pages/home/Home';
import Layout from './pages/common/Layout';
import Auth from './pages/common/Auth';
import ServerErrorProc from './pages/common/ServerErrorProc';
import ApplicationError from './pages/common/ApplicationError';
import NotFoundPage from './pages/common/NotFoundPage';
import NotFoundDataPage from './pages/common/NotFoundDataPage';
import ChangePassword from './pages/common/ChangePassword';
import ScrollTop from './pages/common/ScrollTop';

const screens = [
  {
    name: urls.COMMON.HOME.name, path: urls.COMMON.HOME.url, component: <Home />,
  },
  {
    name: urls.PROFILE.PASSWORD.name,
    path: urls.PROFILE.PASSWORD.url,
    component: <ChangePassword />,
  },
  {
    name: urls.COMMON.SYSTEM_ERROR.name,
    path: urls.COMMON.SYSTEM_ERROR.url,
    component: <ApplicationError />,
  },
];

function App() {
  return (
    <>
      <ServerErrorProc />
      <ScrollTop />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPasswordToken />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />

        {screens.map((screen) => (
          <Route
            path={screen.path}
            key={screen.name}
            element={(
              <Auth>
                <Layout>{screen.component}</Layout>
              </Auth>
            )}
          />
        ))}
        {urls.OPPORTUNITY.concat(urls.SYSTEM_SETTING)
          .map((screen) => (
            <Route
              path={screen.url}
              key={screen.name}
              element={(
                <Auth>
                  <Layout>{screen.component}</Layout>
                </Auth>
              )}
            />
          ))}
        <Route path="/not-found" element={<Layout><NotFoundDataPage /></Layout>} />
        <Route path="*" element={<Layout><NotFoundPage /></Layout>} />
      </Routes>
    </>
  );
}

export default App;
