import React from 'react';
import {
  TableCell,
} from '@mui/material';
import { Link } from '../../../components/atoms/Base';

export default function PcTable(props) {
  const {
    row, headCells, url,
  } = props;

  return (
    <>
      {headCells.map((headCell, idx) => {
        let cmp = (<TableCell style={{ whiteSpace: 'nowrap' }} key={headCell.id}>{row[headCell.id]}</TableCell>);
        if (idx === 0 && url) {
          cmp = (
            <TableCell key={headCell.id}>
              <Link to={url + row.id}>{row[headCell.id]}</Link>
            </TableCell>
          );
        }
        if (headCell.numeric) {
          let value = row[headCell.id];
          if (value && typeof value === 'number' && !Number.isNaN(value)) {
            value = value.toLocaleString();
          }
          cmp = (<TableCell style={{ padding: '0px 10px', whiteSpace: 'nowrap' }} key={headCell.id}>{value}</TableCell>);
        }
        return (
          cmp
        );
      })}
    </>
  );
}
