import React, { useState } from 'react';
import {
  TableCell, IconButton, Menu, MenuItem, Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from '../../atoms/Base';

export default function PcTable(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    row, headCells, url, onOpen,
    onDelete, onEdit, openedMenuRowId,
    setOpenedMenuRowId, setOpenDelete,
  } = props;

  const handleClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setOpenedMenuRowId(rowId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedMenuRowId(null);
  };

  return (
    <>
      {headCells.map((headCell, idx) => {
        let cmp = (<TableCell style={{ whiteSpace: 'nowrap' }} key={headCell.id}>{row[headCell.id]}</TableCell>);
        if (idx === 0 && url) {
          cmp = (
            <TableCell key={headCell.id}>
              <Link to={url + row.id}>{row[headCell.id]}</Link>
            </TableCell>
          );
        }
        if (idx === 0 && onOpen) {
          cmp = (
            <TableCell key={headCell.id}>
              <Button onClick={onOpen}>{row[headCell.id]}</Button>
            </TableCell>
          );
        }
        if (headCell.numeric) {
          let value = row[headCell.id];
          if (value && typeof value === 'number' && !Number.isNaN(value)) {
            value = value.toLocaleString();
          }
          cmp = (<TableCell style={{ padding: '0px 10px', whiteSpace: 'nowrap' }} key={headCell.id}>{value}</TableCell>);
        }
        return (
          cmp
        );
      })}
      {(onDelete || onEdit) && (
        <TableCell style={{
          padding: '0px',
          position: 'sticky',
          right: '0',
          backgroundColor: 'white',
        }}
        >
          {((onDelete && row.deleteable) || (onEdit && row.deleteable)) && (
            <IconButton onClick={(event) => handleClick(event, row.id, row)}>
              <ExpandMoreIcon size="small" />
            </IconButton>
          )}

          <Menu
            anchorEl={anchorEl}
            open={openedMenuRowId === row.id}
            onClose={handleClose}
          >
            {onEdit && (
              <MenuItem
                onClick={(e) => {
                  onEdit(e, openedMenuRowId, row);
                  setOpenedMenuRowId(null);
                }}
              >
                編集
              </MenuItem>
            )}
            {(onDelete && row.deleteable) && (
              <MenuItem
                onClick={() => { setOpenDelete(true); }}
              >
                削除
              </MenuItem>
            )}
          </Menu>
        </TableCell>
      )}
    </>
  );
}
