const ROOT_URL = 'https://api.catering-selection.jp';

export const REST_URL = `${ROOT_URL}/agency`;

export const REST_API = {
  COMMON: {
    LOGIN: `${REST_URL}/authenticate`,
    LOGIN_INFO: `${REST_URL}/login-info`,
    RESET_PASSWORD: `${REST_URL}/reset-password`,
  },
  HOME: `${REST_URL}/todos`,
  SELECT: {
    CODE: `${REST_URL}/select/codes`,
    USER: `${REST_URL}/select/users`,
  },
  CONDITION: {
    CONDITION: `${ROOT_URL}/common/conditions`,
    CONDITION_ID: `${ROOT_URL}/common/conditions/{id}`,
  },
  OPPORTUNITY: {
    OPPORTUNITY: {
      OPPORTUNITY: `${REST_URL}/opportunities`,
      OPPORTUNITY_ID: `${REST_URL}/opportunities/{id}`,
      OPPORTUNITY_ORDER: `${REST_URL}/opportunities/order-details`,
      OPPORTUNITY_CALENDAR: `${REST_URL}/opportunities/calendar`,
    },
    OPPORTUNITY_ATTACH: {
      OPPORTUNITY_FILE: `${REST_URL}/opportunities/opportunity-files`,
      OPPORTUNITY_DELIVERY_ROUTE_FILE: `${REST_URL}/opportunities/opportunity-delivery-route-files`,
      FILE: `${REST_URL}/opportunities/file`,
    },
  },
  SYSTEM_SETTING: {
    LOGIN_USER: {
      LOGIN_USER: `${REST_URL}/login-users`,
      LOGIN_USER_CHANGE_PASSWORD: `${REST_URL}/login-users/change-password`,
      LOGIN_USER_ID: `${REST_URL}/login-users/{id}`,
    },
  },
};
