import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Box,
} from '@mui/material';
import OpportunityDetailInfo from './OpportunityDetailInfo';
import OrderList from './attached/OrderList';
import { opportunityDetailActions } from '../../../../redux/opportunity/opportunityDetail/opportunityDetailState';
import {
  withParams, TabPanel, SuccessSnackbar,
} from '../../../../components/atoms/Base';
import Tabs from '../../../../components/templates/Tabs';
import FileDataTable from './attached/FileDataTable';
import DeliveryFileTable from './attached/DeliveryFileTable';

class OpportunityDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    this.state = {
      id,
      successMessage: '',
      isSuccessOpen: false,
      tabValue: 0,
    };
    if (id) {
      props.dispatch(opportunityDetailActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunityInfo.isSaveSuccess !== this.props.opportunityInfo.isSaveSuccess) {
      if (this.props.opportunityInfo.isSaveSuccess) {
        this.saveSuccess(this.props.opportunityInfo.saveId);
      }
    }
  }

  saveSuccess = (saveId) => {
    this.props.dispatch(opportunityDetailActions.resetFlg());
    this.setState({ id: saveId, isSuccessOpen: true, successMessage: '保存しました。' });
    this.props.dispatch(opportunityDetailActions.getData({ id: saveId }));
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  render() {
    const {
      id,
      successMessage,
      isSuccessOpen,
      tabValue,
    } = this.state;

    return (
      <>
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={id ? 5 : 12}>
            <OpportunityDetailInfo
              id={id}
            />
          </Grid>
          {id && (
            <Grid item xs={12} md={7}>
              <Tabs
                value={tabValue}
                onChange={(e, value) => this.setState({ tabValue: value })}
                tabs={['詳細', 'ファイル', '搬入経路ファイル']}
              />
              <TabPanel value={tabValue} index={0}>
                <OrderList id={id} />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Box mt={3}>
                  <FileDataTable orderId={id} />
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Box mt={3}>
                  <DeliveryFileTable orderId={id} />
                </Box>
              </TabPanel>
            </Grid>
          )}
        </Grid>
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message={successMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  opportunityInfo: state.opportunityDetailStore,
  loginUser: state.loginStore,
});
export default withParams(connect(mapStateToProps)(OpportunityDetail));
