import React from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';
import Dialog from '../atoms/Dialog';

export default function DeleteDialog(props) {
  const isLoading = useSelector((state) => state.commonStore.isLoading);
  const {
    procName,
    onDelete,
    open,
    onClose,
  } = props;

  return (
    <Dialog isOpen={open} onClose={onClose} title={`選択した${procName}の削除`} isError>
      <DialogContent dividers>{`選択した${procName}を削除します。よろしいですか？`}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <LoadingButton
          onClick={onDelete}
          loading={isLoading}
          color="secondary"
        >
          削除する
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
