import React, { useEffect } from 'react';
import {
  Box, useTheme, Button,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  experimentalStyled as styled,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
  Save as SaveIcon,
  FilterAlt as FilterAltIcon,
} from '@mui/icons-material';
import { SuccessSnackbar } from '../../atoms/Base';
import ConditionSaveDialog from './ConditionSaveDialog';
import ConditionListDialog from './ConditionListDialog';
import { conditionActions } from '../../../redux/common/condition/conditionState';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#fff',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const AccordionActions = styled(Box)(({ theme }) => ({
  borderStyle: 'solid',
  borderColor: 'rgba(0, 0, 0, .125)',
  borderWidth: '1px 1px 1px 1px',
  marginBottom: '15px',
  padding: '10px',
  display: 'flex',
  backgroundColor: '#fff',
  [theme.breakpoints.down('md')]: {
    display: 'block',
    textAlign: 'center',
  },
}));

const AccordionActionOne = styled(Box)(({ theme }) => ({
  flex: 1,
  [theme.breakpoints.down('md')]: {
    marginBottom: '10px',
  },
}));

export default function SearchBox(props) {
  const [expanded, setExpanded] = React.useState(true);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [openSave, setOpenSave] = React.useState(false);
  const [openCondition, setOpenCondition] = React.useState(false);
  const isCreateSuccess = useSelector((state) => state.conditionStore.isCreateSuccess);
  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    onSearch, onClear, children,
    propsExpanded, condition, targetScreen, reSearch,
  } = props;

  useEffect(() => {
    if (propsExpanded && expanded !== propsExpanded) {
      setExpanded(propsExpanded);
    }
  }, [propsExpanded]);
  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(conditionActions.resetFlg());
      setSuccessOpen(true);
      setOpenSave(false);
    }
  }, [isCreateSuccess]);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <>
      <Box>
        <Accordion expanded={expanded} onChange={handleExpansion}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Box fontWeight="bold">検索条件</Box>
          </AccordionSummary>
          <AccordionDetails>
            {children}
          </AccordionDetails>
        </Accordion>
        <AccordionActions>
          <AccordionActionOne>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              color="inherit"
              size="small"
              onClick={() => { setSuccessOpen(false); setOpenSave(true); }}
              sx={{
                marginRight: '10px',
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              }}
            >
              検索条件を保存
            </Button>
            <Button variant="contained" color="inherit" size="small" onClick={() => { setSuccessOpen(false); setOpenCondition(true); }} startIcon={<FilterAltIcon />}>
              保存した条件
            </Button>
          </AccordionActionOne>
          <Box>
            <Button variant="contained" color="inherit" size="small" onClick={onClear} sx={{ marginRight: '10px' }}>
              クリア
            </Button>
            <Button variant="contained" color="primary" size="small" onClick={onSearch}>
              検索
            </Button>
          </Box>
        </AccordionActions>
      </Box>
      <ConditionSaveDialog
        isOpen={openSave}
        onClose={() => setOpenSave(false)}
        condition={condition}
        targetScreen={targetScreen}
      />
      <ConditionListDialog
        isOpen={openCondition}
        onClose={() => setOpenCondition(false)}
        selectRow={(d) => { setOpenCondition(false); reSearch(JSON.parse(d)); }}
        targetScreen={targetScreen}
      />
      <SuccessSnackbar
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        message="保存をしました。"
      />
    </>
  );
}
