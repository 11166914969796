import React from 'react';
import { connect } from 'react-redux';
import { urls } from '../../constants/frontUrls';
import { loginActions } from '../../redux/login/loginState';

class Auth extends React.Component {
  static reset() {
    localStorage.clear();
    window.location.href = urls.COMMON.LOGIN.url;
  }

  constructor(props) {
    super(props);
    this.props.dispatch(loginActions.executeGetLoginInfo());
  }

  componentDidUpdate(prevProps) {
    if (this.props.loginInfo !== prevProps.loginInfo) {
      if (this.props.loginInfo.isLoginInfoError) {
        Auth.reset();
      }
    }
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  loginInfo: state.loginStore,
});
export default connect(mapStateToProps)(Auth);
