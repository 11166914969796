import {
  //    call,
  put, takeLatest,
} from 'redux-saga/effects';
// import { REST_API } from '../../../constants/serverUrls';
import { orderActions } from './orderState';

const content = [
  {
    id: 49, itemNameStr: 'クロス', unitPrice: '9,000', quantity: '1', taxRate: 2, taxRateStr: '10%', totalAmount: '10,800',
  }, {
    id: 50, itemNameStr: '中華セット', unitPrice: '10,800', quantity: '2', taxRate: 1, taxRateStr: '8%', totalAmount: '21,600',
  }, {
    id: 51, itemNameStr: 'オプション', unitPrice: '', quantity: '', taxRate: '', taxRateStr: '', totalAmount: '',
  },
];

function* getDatas({ payload }) {
  try {
    // const result = yield call(getDatas, REST_API.OPPORTUNITY.ORDER.ORDER, payload);
    let result = { payload };
    result = {
      content,
      totalElements: 3,
    };
    yield put(orderActions.getDatasSuccess(result));
  } catch (_) { }
}

function* orderSaga() {
  yield takeLatest('order/getDatas', getDatas);
}

export default orderSaga;
