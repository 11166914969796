import React from 'react';
import { connect } from 'react-redux';
import {
  Grid,
} from '@mui/material';
import {
  FileViewer,
  withParams,
} from '../../../components/atoms/Base';
import { fileActions } from '../../../redux/opportunity/file/fileState';

class FilePreview extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    const { delivery } = this.props;

    this.state = {
      url: '',
    };
    if (id) {
      props.dispatch(fileActions.getUrl({ id, delivery }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fileInfo.url !== this.props.fileInfo.url) {
      if (this.props.fileInfo.url) {
        this.setData(this.props.fileInfo.url);
      }
    }
  }

  setData = (url) => {
    this.setState({ url });
  };

  render() {
    const { url } = this.state;
    return (
      <Grid>
        <FileViewer url={url} />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  fileInfo: state.fileStore,
});

export default withParams(connect(mapStateToProps)(FilePreview));
