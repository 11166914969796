import { createSlice } from '@reduxjs/toolkit';

export const fileSlice = createSlice({
  name: 'file',
  initialState: {
    isDeleteSuccess: false,
  },
  reducers: {
    getUrl: () => {},
    getUrlSuccess: (state, action) => {
      state.url = action.payload;
    },
  },
});

export const fileActions = fileSlice.actions;

export default fileSlice.reducer;
