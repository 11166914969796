import React from 'react';
import {
  TableCell, TableContainer, Table, TableBody, TableRow, Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomButton = styled(Button)(() => ({
  textAlign: 'left',
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: 'rgba(30, 144, 255, 0)',
  },
}));

export default function SmartPhoneTable(props) {
  const { row, headCells, onClick } = props;

  return (
    <TableCell sx={{ padding: 1 }}>
      <TableContainer>
        <Table>
          <TableBody>
            {headCells.map((headCell, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
              >
                <TableCell sx={{ padding: '5px', width: '20%' }}>{`${headCell.label}`}</TableCell>
                {index === 0 && (
                <TableCell sx={{ padding: '5px' }}>
                  {row[headCell.id]}
                </TableCell>
                )}
                {index !== 0 && (
                <TableCell sx={{ padding: '5px' }}>
                  <CustomButton onClick={() => onClick(row)}>
                    {row[headCell.id]}
                  </CustomButton>
                </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableCell>
  );
}
