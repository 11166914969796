import { all } from '@redux-saga/core/effects';

import conditionSaga from './common/condition/conditionSaga';
import loginSaga from './login/loginSaga';
import homeSaga from './home/homeSaga';

import opportunitySaga from './opportunity/opportunity/opportunitySaga';
import opportunityDetailSaga from './opportunity/opportunityDetail/opportunityDetailSaga';
import opportunityAttachSaga from './opportunity/opportunity/opportunityAttachSaga';
import fileSaga from './opportunity/file/fileSaga';

import loginUserSaga from './systemSetting/loginUser/loginUserSaga';
import orderSaga from './opportunity/order/orderSaga';

export default function* rootSaga() {
  yield all([
    conditionSaga(),
    loginSaga(),
    homeSaga(),

    opportunitySaga(),
    opportunityDetailSaga(),
    opportunityAttachSaga(),
    fileSaga(),
    orderSaga(),

    loginUserSaga(),
  ]);
}
