import React from 'react';
import {
  TableCell, TableContainer, Table, TableBody, TableRow, Button, Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function SmartPhoneTable(props) {
  const {
    row, headCells, url, isNotDetail,
  } = props;
  const navigate = useNavigate();
  const onClick = () => {
    navigate(url + row.id);
  };

  return (
    <TableCell sx={{ padding: 1 }}>
      {!isNotDetail && (
        <Box textAlign="right"><Button variant="outlined" size="small" onClick={onClick}>詳細へ</Button></Box>
      )}
      <TableContainer>
        <Table>
          <TableBody>
            {headCells.map((headCell) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
              >
                <TableCell sx={{ padding: '5px' }}>{`${headCell.label}`}</TableCell>
                <TableCell sx={{ padding: '5px' }}>
                  {headCell.numeric && typeof row[headCell.id] === 'number' && !Number.isNaN(row[headCell.id])
                    ? row[headCell.id].toLocaleString()
                    : row[headCell.id]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableCell>
  );
}
