import commonReducer from './common/common/commonState';
import conditionReducer from './common/condition/conditionState';
import loginReducer from './login/loginState';

import homeReducer from './home/homeState';

import opportunityReducer from './opportunity/opportunity/opportunityState';
import opportunityDetailReducer from './opportunity/opportunityDetail/opportunityDetailState';
import opportunityAttachReducer from './opportunity/opportunity/opportunityAttachState';
import fileReducer from './opportunity/file/fileState';

import loginUserReducer from './systemSetting/loginUser/loginUserState';
import orderReducer from './opportunity/order/orderState';

const rootReducer = {
  loginStore: loginReducer,
  commonStore: commonReducer,
  conditionStore: conditionReducer,
  homeStore: homeReducer,

  opportunityListStore: opportunityReducer,
  opportunityDetailStore: opportunityDetailReducer,
  opportunityAttachStore: opportunityAttachReducer,
  orderStore: orderReducer,
  fileStore: fileReducer,

  loginUserStore: loginUserReducer,
};

export default rootReducer;
