import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField, Box,
} from '@mui/material';
import { format } from 'date-fns';
import Calendar from './calendar/Calendar';
import { opportunityListActions } from '../../../redux/opportunity/opportunity/opportunityState';
import { FormTitle } from '../../../components/atoms/Base';
import DataTable from '../../../components/templates/dataTable/DataTable';
import detailUrl from '../../../constants/frontUrls';
import SearchBox from '../../../components/templates/searchBox/SearchBox';

export const DEFAULT_SEARCH_CONDITION = {
  customerOrCompanyName: '',
  customerPhone: '',
  keyword: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
  targetMonth: '',
};

class OpportunityList extends React.Component {
  constructor(props) {
    super(props);
    const { displayType, statuses } = this.props;

    this.headCells = [
      { id: 'orderNumber', label: '注文番号', sortId: 'order_number' },
      { id: 'opportunityNumber', label: '案件番号', sortId: 'opportunity_number' },
      { id: 'shopName', label: '店舗名', sortId: 'shop_name' },
      { id: 'heldDate', label: '開催日', sortId: 'held_date' },
      { id: 'deliveryAddress', label: '納品先', sortId: 'delivery_address' },
      { id: 'driverName', label: 'ドライバー名', sortId: 'driver_name' },
      { id: 'driverPhone', label: 'ドライバー電話番号', sortId: 'driver_phone' },
      { id: 'driverPickupTime', label: 'ドライバーピックアップ時間', sortId: 'driver_pickup_time' },
    ];

    let searchCondition = props.opportunityInfo.searchCondition || DEFAULT_SEARCH_CONDITION;
    searchCondition = { ...searchCondition, opportunityStatusCodes: statuses || [] };

    if (displayType === 'calendar') {
      searchCondition = {
        ...DEFAULT_SEARCH_CONDITION,
        targetMonth: format(new Date(), 'yyyy/MM/dd'),
        statuses: [],
      };
      this.props.dispatch(opportunityListActions.getCalendars(searchCondition));
    } else {
      this.props.dispatch(opportunityListActions.getDatas(searchCondition));
    }

    this.state = {
      displayType: displayType || 'table',
      datas: {},
      searchCondition,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunityInfo.datas !== this.props.opportunityInfo.datas) {
      if (this.props.opportunityInfo.datas) {
        this.setDatas(this.props.opportunityInfo.datas);
      }
    }
    if (prevProps.displayType !== this.props.displayType
      || prevProps.statuses !== this.props.statuses) {
      this.onSearchConst(this.props.displayType, this.props.statuses);
    }
  }

  onSearchConst = (displayType, statuses) => {
    const { searchCondition } = this.state;
    let tempSearchCondition = { ...searchCondition, opportunityStatusCodes: statuses || [] };

    if (displayType === 'calendar') {
      tempSearchCondition = {
        ...DEFAULT_SEARCH_CONDITION,
        targetMonth: format(new Date(), 'yyyy/MM/dd'),
        opportunityStatusCodes: [],
      };
      this.props.dispatch(opportunityListActions.getCalendars(tempSearchCondition));
    } else {
      this.props.dispatch(opportunityListActions.getDatas(tempSearchCondition));
    }
    this.setState({
      searchCondition: tempSearchCondition, displayType: displayType || 'table',
    });
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({
      searchCondition: {
        ...searchCondition,
        [name]: value,
      },
    });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    const { displayType } = this.state;
    this.props.dispatch(opportunityListActions.setSearchCondition(searchCondition));
    if (displayType === 'calendar') {
      const tempSearchCondition = {
        ...searchCondition,
        targetMonth: format(new Date(), 'yyyy/MM/dd'),
      };
      this.setState({ searchCondition: tempSearchCondition });
      this.props.dispatch(opportunityListActions.getCalendars(tempSearchCondition));
    } else {
      const tempSearchCondition = {
        ...searchCondition,
        targetMonth: '',
      };
      this.setState({ searchCondition: tempSearchCondition });
      this.props.dispatch(opportunityListActions.getDatas(tempSearchCondition));
    }
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onCalendarResearch = (targetMonth) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      targetMonth,
    };
    this.setState({ searchCondition: tempCondition });
    this.props.dispatch(opportunityListActions.getCalendars(tempCondition));
  };

  render() {
    const {
      displayType, datas, searchCondition,
    } = this.state;

    return (
      <Box width="100%">
        {displayType === 'table' && (
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          targetScreen="opportunity"
          reSearch={this.reSearch}
          condition={searchCondition}
          simpleAreaOneText="お客様名/会社名"
          simpleAreaOne={(
            <TextField value={searchCondition.customerOrCompanyName} autoComplete="off" fullWidth name="customerOrCompanyName" onChange={this.onChange} />
          )}
          simpleAreaTwoText="お客様電話番号"
          simpleAreaTwo={(
            <TextField autoComplete="off" value={searchCondition.customerPhone} fullWidth name="customerPhone" onChange={this.onChange} />
          )}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <FormTitle title="お客様名/会社名" />
              <TextField value={searchCondition.customerOrCompanyName} autoComplete="off" fullWidth name="customerOrCompanyName" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTitle title="お客様電話番号" />
              <TextField autoComplete="off" value={searchCondition.customerPhone} fullWidth name="customerPhone" onChange={this.onChange} />
            </Grid>
          </Grid>
          <Box>
            <FormTitle title="フリーワード" attention="店舗使用用備考、店舗向け備考、商品内容備考、ユーザー向け備考、店舗受注可否コメント" />
            <TextField autoComplete="off" value={searchCondition.keyword} fullWidth name="keyword" onChange={this.onChange} />
          </Box>
        </SearchBox>
        )}
        <Box style={{ marginBottom: '10px' }}>
          {displayType === 'table' && (
            <DataTable
              url={this.props.url || detailUrl.OPPORTUNITY_CHECK_DETAIL}
              rows={datas.content || []}
              headCells={this.headCells}
              procName="案件"
              page={searchCondition.page}
              rowsPerPage={searchCondition.records}
              totalElements={datas.totalElements || 0}
              onChangePage={this.onChangePage}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
              onDelete={this.onDelete}
              handleRequestSort={this.handleRequestSort}
              order={searchCondition.order}
              orderBy={searchCondition.orderBy}
              disabled
            />
          )}
          {displayType === 'calendar' && (
          <Calendar searchCondition={searchCondition} onSearch={this.onCalendarResearch} />)}
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  opportunityInfo: state.opportunityListStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(OpportunityList);
