import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { opportunityListActions } from './opportunityState';
import { getDatas } from '../../../services/base.service';

function* getOpportunityDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY, payload);
    yield put(opportunityListActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCalendars({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY_CALENDAR, payload);
    yield put(opportunityListActions.getCalendarsSuccess(result));
  } catch (_) { }
}

function* opportunityListSaga() {
  yield takeLatest('opportunityList/getDatas', getOpportunityDatas);
  yield takeLatest('opportunityList/getCalendars', getCalendars);
}

export default opportunityListSaga;
