import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { opportunityAttachActions } from '../../../../../redux/opportunity/opportunity/opportunityAttachState';
import DataTable from './dataTable/DataTable';
import detailUrl from '../../../../../constants/frontUrls';

const headCells = [
  { id: 'fileName', label: '件名', sortId: 'file_name' },
  { id: 'createdAt', label: '作成日', sortId: 'created_at' },
  { id: 'createdBy', label: '作成者', sortId: 'created_by' },
];

export default function DeliveryFileTable(props) {
  const [data, setData] = useState({ content: [], totalElements: 0 });
  const [searchCondition, setSearchCondition] = useState({
    page: 0, records: 50, order: 'desc', orderBy: 'created_at',
  });
  const dispatch = useDispatch();
  const fileDatas = useSelector((state) => state.opportunityAttachStore.deliveryFiles);

  const { orderId } = props;

  const search = (condition) => {
    dispatch(opportunityAttachActions.getDeliveryFiles({ ...condition, orderId }));
  };

  useEffect(() => {
    if (orderId) {
      dispatch(opportunityAttachActions.getDeliveryFiles(
        { ...searchCondition, orderId },
      ));
    }
  }, [orderId]);

  useEffect(() => {
    search(searchCondition);
  }, []);
  useEffect(() => {
    if (orderId && fileDatas) {
      setData(fileDatas);
    }
  }, [fileDatas]);

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy: orderBy || 'created_at',
      order: (orderBy || searchCondition.orderBy === 'created_at') ? order : 'desc',
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const getFile = (row) => {
    const pageUrl = detailUrl.DELIVERY_FILE + row.id;
    window.open(pageUrl, '_blank');
  };

  return (
    <div>
      <DataTable
        rows={data.content || []}
        headCells={headCells}
        page={searchCondition.page}
        rowsPerPage={searchCondition.records}
        totalElements={data.totalElements}
        onClick={getFile}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleRequestSort={handleRequestSort}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
      />
    </div>
  );
}
