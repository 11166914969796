import React from 'react';
import {
  DialogContent, DialogActions,
  Box, Button, Alert,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../../components/atoms/Dialog';
import {
  PasswordInput, FormTitle, FormErrorText,
} from '../../../components/atoms/Base';
import Validation from './validation';
import { loginUserActions } from '../../../redux/systemSetting/loginUser/loginUserState';

export default function LoginUserPasswordDialog(props) {
  const {
    isOpen, onClickModalClose, id,
  } = props;
  const [password, setPassword] = React.useState('');
  const [checkPassword, setCheckPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [checkPasswordError, setCheckPasswordError] = React.useState('');
  const [updatedAt, setUpdatedAt] = React.useState(null);
  const data = useSelector((state) => state.loginUserStore.data);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isOpen) {
      dispatch(loginUserActions.getData({ id }));
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data) {
      setUpdatedAt(data.updatedAt);
    }
  }, [data]);

  const onSavePassword = () => {
    const passError = Validation.formValidate('password', password, null);
    const checkPassError = Validation.formValidate('checkPassword', password, checkPassword);
    const isError = (passError || checkPassError);
    setPasswordError(passError);
    setCheckPasswordError(checkPassError);

    if (isError) {
      return;
    }
    const passwordForm = { id, password, updatedAt };
    dispatch(loginUserActions.savePassword(passwordForm));
    onClickModalClose();
  };

  const onPasswordChange = (event) => {
    const { value } = event.target;

    let tempCheckPasswordError = checkPasswordError;
    if (checkPassword) {
      tempCheckPasswordError = Validation.formValidate('checkPassword', value, checkPassword);
    }
    setPassword(value);

    setPasswordError(Validation.formValidate('password', value, null));
    setCheckPasswordError(tempCheckPasswordError);
  };

  const oncheckPasswordChange = (event) => {
    const { value } = event.target;
    setCheckPassword(value);
    setCheckPasswordError(Validation.formValidate('checkPassword', password, value));
  };

  return (
    <Dialog
      fullWidth
      title="パスワード変更"
      isOpen={isOpen}
      onClose={onClickModalClose}
    >
      <DialogContent dividers>
        <Box mt={1}>
          <Alert severity="info">
            <Box mb={1}>・8文字以上、16文字以下で設定してください</Box>
            <Box>・半角英数字、記号(! # $ @ . _)のみで入力出来ます</Box>
          </Alert>
        </Box>
        <Box my={2}>
          <FormTitle title="パスワード" />
          <PasswordInput
            name="password"
            value={password}
            error={!!passwordError}
            onChange={onPasswordChange}
          />
          <FormErrorText>{passwordError}</FormErrorText>
        </Box>
        <Box my={2}>
          <FormTitle title="確認パスワード" />
          <PasswordInput
            name="checkPassword"
            value={checkPassword}
            error={!!checkPasswordError}
            onChange={oncheckPasswordChange}
          />
          <FormErrorText>{checkPasswordError}</FormErrorText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClickModalClose}>キャンセル</Button>
        <Button onClick={onSavePassword} color="primary">
          変更
        </Button>
      </DialogActions>
    </Dialog>
  );
}
