import React from 'react';

import OpportunityList from '../pages/opportunity/opportunity/OpportunityList';
import OpportunityDetail from '../pages/opportunity/opportunity/detail/OpportunityDetail';

import LoginUserList from '../pages/systemSetting/loginUser/LoginUserList';
import LoginUserDetail from '../pages/systemSetting/loginUser/LoginUserDetail';
import FilePreview from '../pages/opportunity/file/FilePreview';

const detailUrl = {
  OPPORTUNITY_CHECK_DETAIL: '/check/opportunity/',
  OPPORTUNITY_WAITING_DETAIL: '/opportunity-waiting/detail/',
  OPPORTUNITY_COMPLETE_DETAIL: '/opportunity-complete/detail/',
  OPPORTUNITY_CALENDAR_DETAIL: '/calendar/detail/',
  FILE: '/file/',
  DELIVERY_FILE: '/delivery-file/',

  SHOP_DETAIL: '/shop/detail/',
  REVIEW_DETAIL: '/review/detail/',
  LOGIN_USER_DETAIL: '/login-user/detail/',
};
export default detailUrl;

export const urls = {
  COMMON: {
    HOME: { name: 'home', url: '/' },
    LOGIN: { name: 'ログイン', url: '/login' },
    SYSTEM_ERROR: { name: 'エラー', url: '/error' },
  },
  OPPORTUNITY: [
    { name: '案件詳細', url: `${detailUrl.OPPORTUNITY_CHECK_DETAIL}:id`, component: <OpportunityDetail /> },
    {
      name: '配達待ち', url: '/opportunity-waiting', component: <OpportunityList statuses={[3]} displayType="table" url={detailUrl.OPPORTUNITY_WAITING_DETAIL} />, isMenuDisplay: true,
    },
    {
      name: '配達完了', url: '/opportunity-complete', component: <OpportunityList statuses={[99]} displayType="table" url={detailUrl.OPPORTUNITY_COMPLETE_DETAIL} />, isMenuDisplay: true,
    },
    {
      name: 'カレンダー', url: '/calendar', component: <OpportunityList displayType="calendar" url={detailUrl.OPPORTUNITY_CALENDAR_DETAIL} />, isMenuDisplay: true, displayType: 'calendar',
    },
    { name: '案件詳細', url: `${detailUrl.OPPORTUNITY_WAITING_DETAIL}:id`, component: <OpportunityDetail /> },
    { name: '案件詳細', url: `${detailUrl.OPPORTUNITY_COMPLETE_DETAIL}:id`, component: <OpportunityDetail /> },
    { name: '案件詳細', url: `${detailUrl.OPPORTUNITY_CALENDAR_DETAIL}:id`, component: <OpportunityDetail /> },
    { name: 'ファイル', url: `${detailUrl.FILE}:id`, component: <FilePreview delivery={false} /> },
    { name: 'ファイル', url: `${detailUrl.DELIVERY_FILE}:id`, component: <FilePreview delivery /> },
  ],
  SYSTEM_SETTING: [
    {
      name: 'ログインユーザー', url: '/login-user', isMenuDisplay: true, component: <LoginUserList />,
    },
    { name: 'ログインユーザー詳細', url: detailUrl.LOGIN_USER_DETAIL, component: <LoginUserDetail /> },
    { name: 'ログインユーザー詳細', url: `${detailUrl.LOGIN_USER_DETAIL}:id`, component: <LoginUserDetail /> },
  ],
  PROFILE: {
    PASSWORD: { name: 'パスワード変更', url: '/change-password' },
  },
};
