import React from 'react';
import {
  TableCell, Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomButton = styled(Button)(() => ({
  textAlign: 'left',
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: 'rgba(30, 144, 255, 0)',
  },
}));

export default function PcTable(props) {
  const {
    row, headCells, onClick,
  } = props;

  return (
    <>
      {headCells.map((headCell) => {
        const key = `${row.id}-${headCell.id}`;
        const value = row[headCell.id];
        let cmp = (
          <TableCell key={key}>
            {value}
          </TableCell>
        );

        if (headCell.id === headCells[0].id) {
          cmp = (
            <TableCell key={key}>
              <CustomButton onClick={() => onClick(row)}>
                {value}
              </CustomButton>
            </TableCell>
          );
        }
        return cmp;
      })}
    </>
  );
}
