import React from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, useMediaQuery,
} from '@mui/material';
import { Pagination } from '../../../../../../components/atoms/Base';
import TableHeaderCell from '../../../../../../components/atoms/TableHeaderCell';
import PcTable from './PcTable';
import SmartPhoneTableWithMenu from './SmartPhoneTable';

export default function DataTable(props) {
  const isPc = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const {
    rows,
    headCells,
    totalElements,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
    onClick,
  } = props;

  const colSpan = headCells.length + 1;

  return (
    <div>
      <Paper>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {isPc && headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={`row-${row.id}`}
                  sx={{ height: '56px' }}
                >
                  {isPc && (
                    <PcTable
                      row={row}
                      headCells={headCells}
                      onClick={onClick}
                    />
                  )}
                  {!isPc && (
                    <SmartPhoneTableWithMenu
                      row={row}
                      headCells={headCells}
                      onClick={onClick}
                    />
                  )}
                </TableRow>
              ))}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={colSpan}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        {!!totalElements && (
          <Pagination
            totalElements={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
}
