import { createSlice } from '@reduxjs/toolkit';

export const opportunityListSlice = createSlice({
  name: 'opportunityList',
  initialState: {
    searchCondition: null,
    datas: {},
  },

  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.datas = {};
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getCalendars: () => {},
    getCalendarsSuccess: (state, action) => {
      state.calendarDatas = action.payload;
    },
  },
});

export const opportunityListActions = opportunityListSlice.actions;

export default opportunityListSlice.reducer;
