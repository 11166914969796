// src/Home.js
import React from 'react';
import {
  Container, Paper, Box, Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <Container>
      <Paper variant="outlined" style={{ padding: '20px', textAlign: 'center' }}>
        <Box>
          存在しないページです。
        </Box>
        <Box mt={2}>
          <Button onClick={() => navigate('/')}>HOMEに戻る</Button>
        </Box>
      </Paper>
    </Container>
  );
}
