import { createSlice } from '@reduxjs/toolkit';

export const loginUserSlice = createSlice({
  name: 'loginUser',
  initialState: {
    datas: {},
    isDeleteSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.datas = {};
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    deleteDatas: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDatasSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getData: () => { },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    savePassword: (state) => {
      state.isPasswordSuccess = false;
    },
    savePasswordSuccess: (state) => {
      state.isPasswordSuccess = true;
    },
  },
});

export const loginUserActions = loginUserSlice.actions;

export default loginUserSlice.reducer;
