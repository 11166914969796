import React, { useState } from 'react';
import { Button } from '@mui/material';
import DeleteDialog from '../templates/DeleteDialog';

export default function DeleteButton(props) {
  const [open, setOpen] = useState(false);
  const {
    procName,
    onDelete,
    disabled,
  } = props;

  const onPreDelete = () => {
    setOpen(true);
    onDelete();
  };

  return (
    <>
      <Button color="secondary" onClick={() => setOpen(true)} disabled={disabled}>削除</Button>
      <DeleteDialog
        open={open}
        onClose={() => setOpen(false)}
        procName={procName}
        onDelete={onPreDelete}
      />
    </>
  );
}
