import {
  call,
  put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { fileActions } from './fileState';
import {
  getDatas,
} from '../../../services/base.service';

function* getUrl({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.OPPORTUNITY_ATTACH.FILE, payload);
    yield put(fileActions.getUrlSuccess(result));
  } catch (e) {
    console.log(e);
  }
}

function* fileSaga() {
  yield takeLatest('file/getUrl', getUrl);
}

export default fileSaga;
