import {
  call,
  takeLatest,
  put,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { opportunityAttachActions } from './opportunityAttachState';
import {
  getDatas,
} from '../../../services/base.service';

function* getFiles({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.OPPORTUNITY_ATTACH.OPPORTUNITY_FILE, payload);
    yield put(opportunityAttachActions.getFilesSuccess(result));
  } catch (_) { }
}

function* getDeliveryFiles({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.OPPORTUNITY_ATTACH.OPPORTUNITY_DELIVERY_ROUTE_FILE, payload);
    yield put(opportunityAttachActions.getDeliveryFilesSuccess(result));
  } catch (_) { }
}

function* opportunityAttachSaga() {
  yield takeLatest('opportunityAttach/getFiles', getFiles);
  yield takeLatest('opportunityAttach/getDeliveryFiles', getDeliveryFiles);
}

export default opportunityAttachSaga;
