import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { loginUserActions } from './loginUserState';
import {
  getDatas, getDataById, saveData, deleteDatas,
} from '../../../services/base.service';

function* getLoginUsers({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SYSTEM_SETTING.LOGIN_USER.LOGIN_USER, payload);
    yield put(loginUserActions.getDatasSuccess(result));
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SYSTEM_SETTING.LOGIN_USER.LOGIN_USER, payload);
    yield put(loginUserActions.deleteDatasSuccess());
  } catch (_) { }
}

function* getData(param) {
  try {
    const data = param.payload;
    const result = yield call(getDataById, REST_API.SYSTEM_SETTING.LOGIN_USER.LOGIN_USER_ID, data);
    yield put(loginUserActions.getDataSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SYSTEM_SETTING.LOGIN_USER.LOGIN_USER, payload);
    yield put(loginUserActions.saveDataSuccess(result));
  } catch (_) { }
}

function* savePassword({ payload }) {
  try {
    yield call(saveData, REST_API.SYSTEM_SETTING.LOGIN_USER.LOGIN_USER_CHANGE_PASSWORD, payload);
    yield put(loginUserActions.savePasswordSuccess());
  } catch (_) { }
}

function* loginUserSaga() {
  yield takeLatest('loginUser/getDatas', getLoginUsers);
  yield takeLatest('loginUser/deleteDatas', deleteData);
  yield takeLatest('loginUser/getData', getData);
  yield takeLatest('loginUser/saveData', save);
  yield takeLatest('loginUser/savePassword', savePassword);
}

export default loginUserSaga;
