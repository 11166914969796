import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { opportunityDetailActions } from '../../../../../redux/opportunity/opportunityDetail/opportunityDetailState';
import DataTable from '../../../../../components/templates/dataTable/DataTable';

const DEFAULT_SEARCH_CONDITION = {
  orderId: '',
  order: 'asc',
  orderBy: 'display_order',
};

const headCells = [
  { id: 'planName', label: '商品名', sortId: 'plan_name' },
  { id: 'quantity', label: '個数', sortId: 'quantity' },
];

export default function OrderList(props) {
  const { id } = props;
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);
  const [searchCondition, setSearchCondition] = useState(DEFAULT_SEARCH_CONDITION);
  const orderDatas = useSelector((state) => state.opportunityDetailStore.details);

  React.useEffect(() => {
    if (orderDatas) {
      setDatas(orderDatas);
    }
  }, [orderDatas]);

  const search = (tempCondition) => {
    dispatch(opportunityDetailActions.getOrderDetails(tempCondition));
  };

  React.useEffect(() => {
    if (id) {
      const tempSearchCondition = { ...DEFAULT_SEARCH_CONDITION, orderId: id };
      setSearchCondition(tempSearchCondition);
      search(tempSearchCondition);
    }
  }, [id]);

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  return (
    <DataTable
      rows={datas}
      headCells={headCells}
      isNoPagination
      handleRequestSort={handleRequestSort}
      order={searchCondition.order}
      orderBy={searchCondition.orderBy}
    />
  );
}
