import React, { useState } from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Paper, Box,
  Tooltip, Button, useMediaQuery,
} from '@mui/material';
import { Pagination } from '../../atoms/Base';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import DeleteDialog from '../DeleteDialog';
import PcTable from './PcTable';
import SmartPhoneTable from './SmartPhoneTable';

export default function DataTable(props) {
  const [openedMenuRowId, setOpenedMenuRowId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const isPc = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const {
    rows,
    procName,
    headCells,
    totalElements,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
    onAddOpen,
    onEdit,
    onDelete,
    isNoPagination,
    url,
    onOpen,
  } = props;

  const onPreDelete = () => {
    onDelete(openedMenuRowId);
    setOpenDelete(false);
    setOpenedMenuRowId(null);
  };

  const colSpan = headCells.length + 1;

  return (
    <div>
      <Box mb={2} textAlign="right">
        {onAddOpen && (
          <Tooltip title={`${procName}の追加`}>
            <Button color="primary" variant="contained" size="small" onClick={onAddOpen}>{`${procName}の追加`}</Button>
          </Tooltip>
        )}
      </Box>
      <Paper elevation={3}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {isPc && headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
                {(isPc && (onDelete || onEdit)) && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  sx={{ height: '56px' }}
                >
                  {isPc && (
                    <PcTable
                      row={row}
                      url={url}
                      onOpen={onOpen}
                      headCells={headCells}
                      onDelete={onDelete}
                      onEdit={onEdit}
                      openedMenuRowId={openedMenuRowId}
                      setOpenedMenuRowId={setOpenedMenuRowId}
                      setOpenDelete={setOpenDelete}
                    />
                  )}
                  {!isPc && (
                    <SmartPhoneTable
                      row={row}
                      url={url}
                      headCells={headCells}
                      onDelete={onDelete}
                      onEdit={onEdit}
                      onOpen={onOpen}
                      openedMenuRowId={openedMenuRowId}
                      setOpenedMenuRowId={setOpenedMenuRowId}
                      setOpenDelete={setOpenDelete}
                    />
                  )}
                </TableRow>
              ))}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={colSpan}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        {!isNoPagination && (
          <Pagination
            totalElements={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        )}

        <DeleteDialog
          open={openDelete}
          onClose={() => {
            setOpenedMenuRowId(null);
            setOpenDelete(false);
          }}
          procName={procName}
          onDelete={onPreDelete}
        />
      </Paper>
    </div>
  );
}
