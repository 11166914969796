import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../constants/serverUrls';
import { homeActions } from './homeState';
import { getDatas } from '../../services/base.service';

function* getTodo() {
  const result = yield call(getDatas, REST_API.HOME);
  yield put(homeActions.getTodoSuccess(result));
}

function* homeSaga() {
  yield takeLatest('home/getTodo', getTodo);
}

export default homeSaga;
