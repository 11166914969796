import React, { useEffect } from 'react';
import {
  Box, DialogActions, TextField,
  Button, DialogContent,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '../../atoms/Dialog';
import { FormTitle } from '../../atoms/Base';
import { conditionActions } from '../../../redux/common/condition/conditionState';

export default function ConditionSaveDialog(props) {
  const [conditionName, setConditionName] = React.useState('');
  const dispatch = useDispatch();

  const {
    isOpen, onClose, condition, targetScreen,
  } = props;

  useEffect(() => {
    if (isOpen) {
      setConditionName('');
    }
  }, [isOpen]);

  const onConditionSave = () => {
    const searchCondition = JSON.stringify(condition);
    dispatch(conditionActions.insert({ targetScreen, name: conditionName, searchCondition }));
  };

  return (
    <Dialog title="検索条件を保存" isOpen={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent dividers>
        <Box mb={2}>現在の検索条件を保存します。</Box>
        <Box>
          <FormTitle title="条件名" isRequired isNonFlex />
          <TextField
            fullWidth
            inputProps={{ maxLength: 200 }}
            autoComplete="off"
            value={conditionName}
            onChange={(e) => setConditionName(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <LoadingButton onClick={onConditionSave} disabled={!conditionName}>保存</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
